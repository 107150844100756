import { gql, useLazyQuery } from '@apollo/client';
import { AnyObject } from '../../../models/types';

interface BGEstimateVars {
    domainId: string;
  }

const GQL_BG_ESTIMATE = gql`
  query getBGEstimate($domainId: String) {
    estimate(domainId: $domainId)
  }
`;


export const useBGEstimateQuery = () => {
    return useLazyQuery<AnyObject, BGEstimateVars>(GQL_BG_ESTIMATE, {
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
    });
};
