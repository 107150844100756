import {
  Button,
  FormField,
  Grid,
  Multiselect,
} from '@amzn/awsui-components-react';
import React, { useCallback, useState } from 'react';
import { ExternalDashboard } from '../../../Domain/Cloudwatch/ExternalDashboard';
import {
  ExternalWidget,
  SelectProps,
} from '../../../Domain/Cloudwatch/model';
import { CommonDomainProps } from '../../../models/types';
import { getDomainIdentifier } from '../../../utils/helpers';
import './../../../Styles/cloudwatch-dasboard.css';
import { ES_CUSTOMER_METRICS_OPTIONS } from './constants';
import { getElasticsearchMetrics } from './utils'

interface ESCloudwatchMetricsForm {
  selectedMetrics: SelectProps.Options;
  isDirty: boolean;
}

interface ESCloudwatchMetricsProps extends CommonDomainProps {
  clientId: string;
  domainName: string;
  rootAccountId: string;
  cellId: string;
  metricRange: any;
  region: string;
  initialValues?: ESCloudwatchMetricsForm;
  utcOffset: number;
}

const EsCustomerMetrics = (props: ESCloudwatchMetricsProps) => {
  const initialValues = props.initialValues || {
    selectedMetrics: [],
    isDirty: false,
  };
  const [formValues, setFormValues] = useState<ESCloudwatchMetricsForm>(initialValues);
  const [externalWidgets, setExternalWidgets] = useState<ExternalWidget[]>([]);

  const isFormInvalid = () => formValues.selectedMetrics.length === 0;

  const handleSubmit = useCallback(() => {
    const selectedMetrics = formValues.selectedMetrics
      ? formValues.selectedMetrics.map((metric) => metric.value)
      : [];
    console.log("i am inside handle submit")
    const customerWidgets = getElasticsearchMetrics(
      props.domainName,
      props.clientId,
      props.cellId,
      props.region,
      selectedMetrics,
      false
    ) as ExternalWidget[];
    setExternalWidgets(customerWidgets);
    console.log("c widgets", customerWidgets)
  }, [formValues, props]);

  return (
    <React.Fragment>
      <Grid
        className="dasboard-explorer"
        gridDefinition={[
          { colspan: 3 },
          { colspan: 3 },
        ]}
      >
        <FormField description="Choose metric type from the list." label="Metric">
          <Multiselect
            selectedOptions={formValues.selectedMetrics}
            deselectAriaLabel={(e) => 'Remove ' + e.label}
            options={ES_CUSTOMER_METRICS_OPTIONS}
            placeholder="Choose options"
            selectedAriaLabel="Selected"
            filteringType="auto"
            onChange={({ detail }) =>
              setFormValues((prevFormValues: ESCloudwatchMetricsForm) => ({
                ...prevFormValues,
                selectedMetrics: detail.selectedOptions,
                isDirty: true,
              }))
            }
          />
        </FormField>
        <Button
          className="explorer-button-checkbox"
          variant="primary"
          disabled={isFormInvalid()}
          onClick={handleSubmit}
        >
          Fetch
        </Button>
      </Grid>
      {externalWidgets && externalWidgets.length > 0 ? (
        <ExternalDashboard
          utcOffset={props.utcOffset}
          widgets={externalWidgets}
          domainIdentifier={getDomainIdentifier(props.clientId, props.domainName)}
          metricRange={props.metricRange}
        />
      ) : null}
    </React.Fragment>
  );
};

export { EsCustomerMetrics };
