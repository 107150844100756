import { gql, useMutation } from '@apollo/client';
import { AnyObject } from '../models/types';

interface ExecuteApiFilteredDataVars {
  url: string;
  domainIdentifier: string;
  filterArguement: string;
  filterValue: string;
  instanceId: string;
}

export const EXECUTE_GET_FILTERED_DATA_API = gql`
  mutation ExecuteGetFilteredDataAPI(
    $url: String!
    $domainIdentifier: String
    $filterArguement: String
    $filterValue: String
    $instanceId: String
  ) {
    executeGetFilteredDataAPI(
      url: $url
      domainIdentifier: $domainIdentifier
      filterArguement: $filterArguement
      filterValue: $filterValue
      instanceId: $instanceId
    )
  }
`;

export const useExecuteFilteredDataApiMutation = () => {
  return useMutation<AnyObject, ExecuteApiFilteredDataVars>(EXECUTE_GET_FILTERED_DATA_API);
};
