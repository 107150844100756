import {
  Button,
  FormField,
  Grid,
  Multiselect,
  SelectProps,
} from '@amzn/awsui-components-react';
import React, { useCallback, useEffect, useState } from 'react';
import { CloudWatch, CloudwatchWidget } from '../../../Domain/Cloudwatch/CloudWatch';
import { Elb, ESEC2, InstanceVolumes } from '../../../models/types';
import './../../../Styles/cloudwatch-dasboard.css';
import { get } from 'lodash';
import { EBS_VOLUME_METRICS_OPTIONS } from './constants';
import {
  getEBSMetrics,
  getEBSVolumeMetrics
} from './utils';

const statsOptions: SelectProps.Options = [
  { label: 'Maximum', value: 'Maximum' },
  { label: 'Minimum', value: 'Minimum' },
  { label: 'Average', value: 'Average' },
  { label: 'Sum', value: 'Sum' },
];

interface EbsForm {
  selectedMetrics: SelectProps.Options;
  instanceIds: SelectProps.Options;
  stats: SelectProps.Options;
  isDirty: boolean;
}

interface EbsProps {
  ec2Instances: ESEC2[];
  region: string;
  clientId: string;
  domainName: string;
  initialValues?: EbsForm;
  rootAccountId: string;
  isOptInRegion: boolean;
}


const Ebs = (props: EbsProps) => {
  const instancesIds = props.ec2Instances.map(
    (instance: ESEC2) => instance.instanceId
  );
  const dataInstancesIds = props.ec2Instances.filter((instance: ESEC2) => instance.nodeType === 'data').map(
    instance => instance.instanceId
  );
  const instanceIdOptions = instancesIds.map((instanceId) => ({ label: instanceId, value: instanceId }));
  const [metricOptions, setMetricOptions] = useState(EBS_VOLUME_METRICS_OPTIONS)
  const [ebsMetric, setEBSMetric] = useState([])
  const cloudWatchProps = {
    region: props.region,
    rootAccountId: props.rootAccountId,
    isOptInRegion: props.isOptInRegion || false,
  };
  const initialValues = props.initialValues
    ? props.initialValues
    : {
        selectedMetrics: [],
        instanceIds: [],
        stats: [{ label: 'Average', value: 'Average' }],
        isDirty: false,
      };
  const [formValues, setFormValues] = useState<EbsForm>(initialValues);
  const [volumeIDOptions, setVolumeIDOptions] = useState([])

  const fetchVolumeIdsByType = (instances: ESEC2[], volumeType: string): string[] => {
    const volumeTypeMap = {
      Root: (volume: InstanceVolumes) => volume.deviceName === '/dev/xvda' && volume.ebs ? volume.ebs.volumeId : '',
      Log: (volume: InstanceVolumes) => volume.deviceName === '/dev/sdb' && volume.ebs ? volume.ebs.volumeId : '',
      Swap: (volume: InstanceVolumes) => volume.deviceName === '/dev/sdc' && volume.ebs ? volume.ebs.volumeId : '',
      Data: (volume: InstanceVolumes) => /\/dev\/sd[f-m]/.test(volume.deviceName) && volume.ebs ? volume.ebs.volumeId : '',
    };

    return instances
      .map(instance => instance.volumes.map(volumeTypeMap[volumeType] || (() => '')).filter(Boolean))
      .flat();
  };

  const [widgets, setWidgets] = useState<CloudwatchWidget[]>([]);
  const [volumeWidgets, setVolumeWidgets] = useState<CloudwatchWidget[]>([]);
  const [volumeType, setVolumeType] = useState('')
  const isFormInvalid = () => {
    return (
      formValues.stats.length === 0 ||
      formValues.selectedMetrics.length === 0
    );
  };

  const onMetricChange = ({ detail }) => {
    const selectedOptions = detail.selectedOptions;
    let ebsSelection = selectedOptions.filter(option => ['Root', 'Log', 'Swap', 'Data'].includes(option.value));
    const microburstingSelection = selectedOptions.filter(option => ['IOPSMicroBursting', 'ThroughputMicroBursting'].includes(option.value));
    const ebs_metrics = microburstingSelection.map(metric => metric.value)
    // Ensure only one EBS metric can be selected
    setEBSMetric(ebs_metrics)
    if (ebsSelection.length > 1) {
      ebsSelection = [ebsSelection.pop()]; // Keep only the last selected EBS metric
    }
    var volume = null
    if (ebsSelection.length >= 1) {
      volume = ebsSelection[0].value
    }
    // Update the selected EBS metric
    setVolumeType(volume);


    // Update the form values and options disabled state
    const updatedSelectedOptions = [...ebsSelection, ...microburstingSelection];
    setFormValues(prevFormValues => ({
      ...prevFormValues,
      selectedMetrics: updatedSelectedOptions,
      isDirty: true
    }));

    // Update the disabled state of the options
    const updatedOptions = metricOptions.map(group => ({
      ...group,
      options: group.options.map(option => ({
        ...option,
        isDisabled: group.label === 'EBS Burst Balance' && ebsSelection.length > 0 && !ebsSelection.includes(option)
      }))
    }));

    setMetricOptions(updatedOptions);
  };

  const handleSubmit = useCallback(() => {
    setFormValues((prevFormValues: EbsForm) => ({
      ...prevFormValues,
      isDirty: false,
    }));
    const stats = formValues.stats
      ? formValues.stats.map((stat: SelectProps.Option) =>
        stat ? stat.label : ''
      )
      : ['Average'];

    const metricNames = formValues.selectedMetrics ? formValues.selectedMetrics: [];
    const selectedInstanceIds = formValues.instanceIds.map(option => option.label);
    const instanceIdsToSend = selectedInstanceIds.length > 0 ? selectedInstanceIds : dataInstancesIds;
    const updatedIOPSWidgets = getEBSMetrics(
      props.domainName,
      props.clientId,
      instanceIdsToSend,
      props.rootAccountId,
      props.region,
      stats,
      true,
      ebsMetric
    ) as CloudwatchWidget[]
    setWidgets(updatedIOPSWidgets);
    const selectedInstances: ESEC2[] = props.ec2Instances.filter((instance: ESEC2) =>
      instanceIdsToSend.includes(instance.instanceId)
    );
    const volumeIds = fetchVolumeIdsByType(selectedInstances, volumeType);
    setVolumeIDOptions(volumeIds)
    if (volumeIds !== []) {
        const ebsVolumeWidget = getEBSVolumeMetrics(
          props.domainName,
          props.clientId,
          props.rootAccountId,
          props.region,
          volumeIds,
          stats,
          true,
            
        ) as CloudwatchWidget[]
        setVolumeWidgets(ebsVolumeWidget)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues]);

  // When it is initial autosubmit
  useEffect(() => {
    if (props.initialValues) {
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.initialValues]);

  return (
    <React.Fragment>
      <Grid
        className="dasboard-explorer"
        gridDefinition={[
          { colspan: 3 },
          { colspan: 3 },
          { colspan: 3 },
          { colspan: 1 },
        ]}
      >
        <FormField description="Choose Volume Type from the list." label="Metric">
          <Multiselect
            selectedOptions={formValues.selectedMetrics}
            deselectAriaLabel={(e) => 'Remove ' + e.label}
            options={metricOptions}
            placeholder="Choose options"
            selectedAriaLabel="Selected"
            filteringType="auto"
            onChange={onMetricChange}
          />
        </FormField>
        <FormField description="Choose stats for the metric." label="Stats">
          <Multiselect
            selectedOptions={formValues.stats}
            deselectAriaLabel={(e) => 'Remove ' + e.label}
            options={statsOptions}
            placeholder="Choose options"
            selectedAriaLabel="Selected"
            onChange={({ detail }) =>
              setFormValues((prevFormValues: EbsForm) => ({
                ...prevFormValues,
                stats: detail.selectedOptions,
                isDirty: true,
              }))
            }
          />
        </FormField>
        <FormField
            description="Choose Instance IDs to look for metrics."
            label="Instance ID"
        >
            <Multiselect
                disabled={instanceIdOptions.length === 0}
                invalid={formValues.stats.length === 0}
                selectedOptions={formValues.instanceIds}
                deselectAriaLabel={(e) => 'Remove ' + e.label}
                options={instanceIdOptions}
                placeholder="Choose options"
                selectedAriaLabel="Selected"
                filteringType="auto"
                onChange={({detail}) =>
                    setFormValues((prevFormValues: EbsForm) => ({
                        ...prevFormValues,
                        instanceIds: detail.selectedOptions,
                        isDirty: true,
                    }))
                }
            />
        </FormField>
        <Button
          className="explorer-button-checkbox"
          variant="primary"
          disabled={isFormInvalid()}
          onClick={handleSubmit}
        >
          Fetch
        </Button>
      </Grid>
      {widgets && widgets.length > 0 ? (
        <CloudWatch
          region={props.region}
          rootAccountId={props.rootAccountId}
          isOptInRegion={props.isOptInRegion}
          widgets={widgets}
        />
      ) : null}
        {volumeWidgets && volumeWidgets.length > 0 ? (
        <CloudWatch
          region={props.region}
          rootAccountId={props.rootAccountId}
          isOptInRegion={props.isOptInRegion}
          widgets={volumeWidgets}
        />
      ) : null}
    </React.Fragment>
  );
};

export { Ebs };