import { PropertyFilterProperty } from '@amzn/awsui-collection-hooks/dist/cjs/interfaces';

export const API_OPTIONS = [
  {
    value: '_cat',
  },
  {
    value: '_cluster',
  },
  {
    value: '_nodes',
  },
  {
    value: '_tasks',
  },
  {
    value: '_snapshot',
  },
  {
    value: '_opendistro',
  },
  {
    value: '_ultrawarm',
  },
  {
    value: '_kraken',
  },
  {
    value: '_cold',
  },
];

export const NEW_API_OPTIONS = [
  {
    value: '_cat',
  },
  {
    value: '_list',
  },
  {
    value: '_cluster',
  },
  {
    value: '_nodes',
  },
  {
    value: '_tasks',
  },
  {
    value: '_snapshot',
  },
  {
    value: '_opendistro',
  },
  {
    value: '_ultrawarm',
  },
  {
    value: '_kraken',
  },
  {
    value: '_cold',
  },
];

// Generate few runtime.
export const APIS = {
  list: [
    {
      value: 'shards'
    },
      {
      value: 'shards?index=your-index-name'
    },
    {
      value: 'indices'
    },
    {
      value: 'indices?health=red'
    }
  ],
  cat: [
    {
      value: 'aliases',
    },
    {
      value: 'allocation',
    },
    {
      value: 'count',
    },
    {
      value: 'fielddata',
    },
    {
      value: 'health',
    },
    {
      value: 'indices',
    },
    {
      value: 'indices?expand_wildcards=all'
    },
    {
      value: 'master',
    },
    {
      value: 'nodeattrs',
    },
    {
      value: 'nodes',
    },
    {
      value: 'nodes?h=ip,id,n&full_id',
    },
    {
      value: 'plugins',
    },
    {
      value: 'recovery?active_only',
    },
    {
      value: 'recovery',
    },
    {
      value: 'repositories',
    },
    {
      value: 'pending_tasks',
    },
    {
      value: 'tasks',
    },
    {
      value: 'thread_pool',
    },
    {
      value: 'shards',
    },
    {
      value: 'segments',
    },
    {
      value: 'snapshots',
    },
    {
      value: 'snapshots/cs-*',
    },
    {
      value: 'snapshots/cs-automated',
    },
    {
      value: 'snapshots/cs-automated-enc',
    },
    {
      value: 'snapshots/cs-ultrawarm',
    },
    {
      value: 'templates',
    },
  ],
  cluster: [
    {
      value: 'thread_pool',
    },
    {
      value: 'allocation/explain',
    },
    {
      value: 'settings',
    },
    {
      value: 'settings?flat_settings'
    },
    {
      value: 'settings?flat_settings&include_defaults',
    },
    {
      value: 'health',
    },
    {
      value: 'state',
    },
    {
      value: 'stats',
    },
  ],
  nodes: [
    {
      value: 'usage',
    },
    {
      value: 'hot_threads',
    },
    {
      value: 'stats',
    },
  ],
  snapshot: [
    {
      value: 'cs-*',
    },
    {
      value: 'cs-automated',
    },
    {
      value: 'cs-automated-enc',
    },
    {
      value: 'cs-automated/_all',
    },
    {
      value: 'cs-automated-enc/_all',
    },
    {
      value: 'cs-ultrawarm',
    },
    {
      value: 'cs-remote-store-translog'
    },
    {
      value: 'cs-remote-store-segment'
    },
    {
      value: 'cs-remote-store-cluster-state'
    }
  ],
  tasks: [],
  opendistro: [
    {
      value: '_ism',
    },
    {
      value: '_security/api/securityconfig',
    },
    {
      value: '_security/api/internalusers',
    },
    {
      value: '_security/api/roles',
    },
    {
      value: '_security/api/rolesmapping',
    },
    {
      value: '_security/api/tenants',
    },
  ],
  ultrawarm: [
    {
      value: 'migration/_status',
    }
  ],
  kraken: [
    {
      value: 'stats',
    },
    {
      value: 'migration/stats/hot_to_warm',
    }
  ],
  cold: [
    {
      value: 'migration/_status',
    },
    {
      value: 'indices/_search',
    },
    {
      value: 'stats',
    }
  ]
};

export const PAGE_SIZE_OPTIONS = [
  { value: 50, label: '50' },
  { value: 100, label: '100' },
  { value: 500, label: '500' },
  { value: 1000, label: '1000' },
  { value: 2000, label: '2000' },
];

export const CAT_SHARDS_FILTERING_PROPERTIES: readonly PropertyFilterProperty[] = [
  {
    propertyLabel: 'index',
    key: 'index',
    groupValuesLabel: 'index',
    operators: [':', '!:', '=', '!=', '>', '>=', '<', '<='],
  },
  {
    propertyLabel: 'shard',
    key: 'shard',
    groupValuesLabel: 'shard',
    operators: [':', '!:', '=', '!=', '>', '>=', '<', '<='],
  },
  {
    propertyLabel: 'prerep',
    key: 'prerep',
    groupValuesLabel: 'prerep',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'state',
    key: 'state',
    groupValuesLabel: 'state',
    operators: [':', '!:', '=', '!=', '>', '>=', '<', '<='],
  },
  {
    propertyLabel: 'docs',
    key: 'docs',
    groupValuesLabel: 'docs',
    operators: [':', '!:', '=', '!=', '>', '>=', '<', '<='],
  },
  {
    propertyLabel: 'store',
    key: 'store',
    groupValuesLabel: 'store',
    operators: [':', '!:', '=', '!=', '>', '>=', '<', '<='],
  },
  {
    propertyLabel: 'ip',
    key: 'ip',
    groupValuesLabel: 'ip',
    operators: [':', '!:', '=', '!=', '>', '>=', '<', '<='],
  },
  {
    propertyLabel: 'node',
    key: 'node',
    groupValuesLabel: 'node',
    operators: [':', '!:', '=', '!=', '>', '>=', '<', '<='],
  },
];

export const CAT_SHARDS_DEFAULT_PREFERENCES = {
  pageSize: 500,
  visibleContent: [
    'index',
    'shard',
    'prerep',
    'state',
    'docs',
    'store',
    'ip',
    'node'
  ],
  wraplines: true,
};

export const catShardsColumnDefintions = [
  {
    id: 'index',
    header: 'index',
    cell: (e) => e.index,
    sortingField: 'index',
  },
  {
    id: 'shard',
    header: 'shard',
    cell: (e) => e.shard,
    sortingField: 'shard',
  },
  {
    id: 'prerep',
    header: 'prerep',
    cell: (e) => e.prerep,
  },
  {
    id: 'state',
    header: 'state',
    cell: (e) => e.state,
  },
  {
    id: 'docs',
    header: 'docs',
    cell: (e) => e.docs,
  },
  {
    id: 'store',
    header: 'store',
    cell: (e) => e.store,
    sortingField: 'store',
  },
  {
    id: 'ip',
    header: 'ip',
    cell: (e) => e.ip,
  },
  {
    id: 'node',
    header: 'node',
    cell: (e) => e.node,
  }
]

export const CAT_SHARDS_VISIBLE_CONTENT_OPTIONS = [
  {
    label: 'Options',
    options: [
      { id: 'index', label: 'index' },
      { id: 'shard', label: 'shard' },
      { id: 'prerep', label: 'prerep' },
      { id: 'state', label: 'state' },
      { id: 'docs', label: 'docs' },
      { id: 'store', label: 'store' },
      { id: 'ip', label: 'ip' },
      { id: 'node', label: 'node' },
    ],
  },
];

export const API_FILTERS_VALUE = {
  shards: [
    {
      value: 'INITIALIZING'
    },
    {
      value: 'RELOCATING'
    },
    {
      value: 'STARTED'
    },
    {
      value: 'UNASSIGNED'
    }
  ],
  indices: [
    {
      value: 'yellow'
    },
    {
      value: 'red'
    },
    {
      value: 'green'
    }
  ]
}

export const API_FILTERS_ARGUEMENTS = {
  shards: [
    {
      value: 'state'
    },
    {
      value: 'index'
    },
    {
      value: 'node'
    }
  ],
  indices: [
    {
      value: 'health'
    }
  ]
}

