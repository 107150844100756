import {
  Button,
  Container,
  Header,
  Select,
  SpaceBetween,
  Popover,
  DateRangePicker,
  ExpandableSection,
  FormField,
  Link, Flashbar,
} from '@amzn/awsui-components-react';
import { CommonDomainProps } from '../../models/types';
import { useParams } from 'react-router';
import React, { useEffect, useRef, useState } from 'react';
import { useRequestOnDemandDataMutation } from '../OnDemand/useRequestOnDemandData'
import moment from 'moment';
import {
  i18nStrings, isValidRequestRangeForOnDemand,
  relativeOptions,
  REQUEST_METRICS_INSTRUCTION_HEADER,
  REQUEST_METRICS_INSTRUCTIONS, timezoneOptions,
} from '../Logs/constants';
import { get } from 'lodash';
import { useGetOnDemandDomainQuery } from '../OnDemand/useGetOnDemandDomainQuery';

// Interface definition
interface QueryInsightsProps extends CommonDomainProps {
  domainIdentifier: string;
  instanceId: string;
  authUser: boolean
}
const QueryInsights = (props: QueryInsightsProps) => {


  // Basic arguments
  const params = useParams();
  const clientId = params['clientId'];
  const domainName = params['domainName'];
  const domainId = `${clientId}:${domainName}`
  const [domainQuery, setDomainQuery] = useState(false)


  // Timezone related
  const [selectedRequestTimeZoneOption, setSelectedRequestTimeZoneOption] =
    useState({
      inputDisplay: 'Browser Timezone',
      label: 'Browser Timezone',
      value: 'Browser Timezone',
      code: new Date()
        .toLocaleTimeString('en-us', { timeZoneName: 'short' })
        .split(' ')[2],
      offset: '',
      mins: new Date().getTimezoneOffset() * -1,
    });

  // Request date set to relative 3 hours by default
  const [requestDate, setRequestDate] = useState<any>({
    type: 'relative',
    amount: 3,
    unit: 'hour',
  });


// Flashbar items
  const [flashbarItems, setFlashbarItems] = useState([]);
  const [dataAvailabilityFlashbarItems, setDataAvailabilityFlashbarItems] = useState([
    {
      type: 'info',
      content: 'Query Insights data is available from May 2024.',
      dismissible: true,
      dismissLabel: 'Dismiss message',
      onDismiss: () => setFlashbarItems([]),
    },
  ])


  // Render Kibana Link
  const [getOnDemandDomain, { loading: getDomainLoading, data: getDomainResponse, error: getDomainError }] = useGetOnDemandDomainQuery()
  const domainResponse = get(getDomainResponse, 'getOnDemandDomain', '')
  useEffect(() => {
    getOnDemandDomain({ variables: {
        domainId: domainId
      }
    });
  },[domainQuery])


  // Handle request metrics
  const [requestOnDemandMetrics] = useRequestOnDemandDataMutation();
  const [startTime, setStartTime] = useState('')
  const [endTime, setEndTime] = useState('')
  const [submit, setSubmit] = useState(false)
  const handleRequestMetrics = async () => {
    var startMoment, endMoment;

    if ('absolute' === requestDate.type) {
      startMoment = moment(requestDate.startDate);
      endMoment = moment(requestDate.endDate);
    } else if ('relative' === requestDate.type) {
      startMoment = moment().subtract(requestDate.amount, requestDate.unit);
      endMoment = moment();
    }

    const startTimeStamp = startMoment.utc().format('YYYYMMDDTHHmm') + 'Z';
    const endTimeStamp = endMoment.utc().format('YYYYMMDDTHHmm') + 'Z';
    setStartTime(startTimeStamp)
    setEndTime(endTimeStamp)
    try{
      var resp;
      resp = await requestOnDemandMetrics({
        variables: {
          domainId: clientId + ':' + domainName,
          eventType: 'QI',
          startTime: startTimeStamp,
          endTime: endTimeStamp,
          reBackfill: 'false'
        },
      });
      if (resp?.data?.requestOnDemandData?.success) {
        const status = resp?.data?.requestOnDemandData?.data;
        setFlashbarItems([
          {
            type: 'success',
            content: status,
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashbarItems([]),
          },
        ]);
      } else {
        const errorMessage = resp?.data?.requestOnDemandData?.error;
        setFlashbarItems([
          {
            type: 'error',
            content: `Error occured while requesting backfill. ${errorMessage}`,
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () =>
              setFlashbarItems([]),
            id: 'on_demand_error',
          },
        ]);
      }
      setSubmit(true)
    } catch (e) {
      // TODO
    }
  };
  const handleRequestDateChange = ({ detail }) => {
    setRequestDate(detail.value);
  };
  const handleRequestTimezoneChange = (selectedOption) => {
    setSelectedRequestTimeZoneOption(selectedOption);
  };
  const [errorMessage, setErrorMessage] = useState(null)

  function renderQueryInsightsDescription() {
    return (
      <div>
        <p>
          To monitor and analyze the search queries within your OpenSearch
          cluster, you can obtain query insights. With minimal performance
          impact, query insights features aim to provide comprehensive insights
          into search query execution, enabling you to better understand search
          query characteristics, patterns, and system behavior during query
          execution stages.
        </p>
        <br />
        <p>
          Query insights facilitate enhanced detection, diagnosis, and
          prevention of query performance issues, ultimately improving query
          processing performance, user experience, and overall system
          resilience.
        </p>
        <br />
        <p>
          Query metrics provide the following measurements that you can get on
          demand for the domain:
        </p>
        <ul>
          <li>
            1. The number of queries per query type (for example, the number of
            match or regex queries)
          </li>
          <li>
            2. The number of queries per aggregation type (for example, the
            number of terms aggregation queries)
          </li>
          <li>
            3. The number of queries per sort order (for example, the number of
            ascending and descending sort queries)
          </li>
          <li>
            4. Histograms of latency for each query type, aggregation type, and
            sort order
          </li>
          <li>
            5. Histograms of CPU for each query type, aggregation type, and sort
            order
          </li>
          <li>
            6. Histograms of memory for each query type, aggregation type, and
            sort order
          </li>
        </ul>
        <br />
        <p>
          For more details, visit the official{" "}
          <a
            href="https://opensearch.org/docs/latest/observing-your-data/query-insights/index/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Query Insights documentation
          </a>
          .
        </p>
      </div>
    );
  }



  // React component default return
  return (
    <>
      <SpaceBetween direction="vertical" size="l">
        <Container
          header={
            <div>
              <Header variant="h2">Query Insights</Header>
            </div>
          }
        >
          <div>{renderQueryInsightsDescription()}</div>
        </Container>
        <Container>
          <Flashbar items={dataAvailabilityFlashbarItems}/>
          <Flashbar items={flashbarItems} />
          <ExpandableSection
            header={
              <Header
                variant="h2"
                info={
                  <div
                    style={{ display: 'inline-block' }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Popover
                      dismissAriaLabel="Close"
                      fixedWidth
                      header={REQUEST_METRICS_INSTRUCTION_HEADER}
                      position="right"
                      size="large"
                      triggerType="text"
                      renderWithPortal={true}
                      content={REQUEST_METRICS_INSTRUCTIONS.map((line) => (
                        <p>{line}</p>
                      ))}
                    >
                      <Link variant="info">Info</Link>
                    </Popover>
                  </div>
                }
              >
                Request Query Insights Metric Data
              </Header>
            }
            variant="container"
          >
            <SpaceBetween direction="horizontal" size="l">
              <FormField label={<strong>Date</strong>}>
                <DateRangePicker style={{ marginRight: '20px', marginLeft : '20px' }}
                                 onChange={handleRequestDateChange}
                                 value={requestDate}
                                 relativeOptions={relativeOptions}
                                 i18nStrings={i18nStrings}
                                 placeholder="Filter by a date and time range"
                                 isValidRange={isValidRequestRangeForOnDemand}
                                 showClearButton={false}
                                 timeOffset={selectedRequestTimeZoneOption.mins}
                />
              </FormField>
              <FormField label={<strong>Time Zone</strong>}>
                <Select style={{ marginRight: '20px', marginLeft : '20px' }}
                        selectedOption={selectedRequestTimeZoneOption}
                        onChange={({ detail }) =>
                          handleRequestTimezoneChange(detail.selectedOption)
                        }
                        options={timezoneOptions}
                        selectedAriaLabel="Selected"
                />
              </FormField>
              <div style={{ marginTop: '26px' }}>
                <Button variant="normal" onClick={handleRequestMetrics}>
                  Submit
                </Button>
              </div>
            </SpaceBetween>
          </ExpandableSection>
        </Container>
        <Container>
          <div>
            <Header variant="h3">
              Kibana Dashboard Link:{' '}
              <a
                href={`https://${domainResponse}/_dashboards`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Query Insights Metrics
              </a>
            </Header>
            <p>
              The above dashboard contains metrics and visualizations around Query Insights Data. Login to the dashboard and follow the below
              steps:
            </p>
            <ul>
              <li>1: Authenticate yourself by signing with your corporate ID i.e by clicking on 'Amazon'.</li>
              <li>2: Click on the sidebar. Click on Dashboard and open 'QueryInsights' dashboards according to your use-case.</li>
              <li>3: Add the domain ident in the filter for which you want to see the metrics.</li>
              <li>4: Choose the timestamp between which you requested the Query Insights Metrics.</li>
            </ul>
          </div>
        </Container>
      </SpaceBetween>
    </>
  );
}

export { QueryInsights };