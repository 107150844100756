import {
    Box,
    Button,
    Container,
    Flashbar,
    FormField,
    Input,
    SpaceBetween,
    Spinner,
  } from '@amzn/awsui-components-react';
  import { get } from 'lodash';
  import React, { useEffect, useState } from 'react';
  import { CodeViewer } from '../../Components/Common/CodeViewer';
  import { CommonDomainProps } from '../../models/types';
  import { AppContext } from '../../utils/appContext';
  import { useBGEstimateQuery } from './hooks/useBGEstimateQuery';
  import { isError } from "../../ErrorHandler/apiErrorHandler";
  import { ErrorMessageComponent } from "../../ErrorHandler/errorMessageComponent";
  import { ApiList } from "../../ErrorHandler/utils";     
  
  const BGEstimatedCompletion = (props: CommonDomainProps) => {
    const appContext = React.useContext(AppContext);
    const domainId = props.domainIdentifier;
    const [errorMessage, setErrorMessage] = useState(null);
  
    var [getEstimate, { loading, data: BGEstimate, error }] =
      useBGEstimateQuery();
  
    useEffect(() => {
      getEstimate({
        variables: {
          domainId: domainId
        },
      });
    }, [domainId, getEstimate]);

    const isErrorPresent = isError(error)
    useEffect(() => {
      if (isErrorPresent) {
        setErrorMessage("Unable to get B/G Estimated time. Failed with " + error.message);
      } else {
        setErrorMessage(null);
      }
    }, [isErrorPresent, error]);
  
    const refresh = () => {
      getEstimate({
        variables: {
          domainId: domainId,
        },
      });
    };
  
    let output = get(BGEstimate, 'estimate', 'Error Fetching Details');
    
    return (
        <Container
          header={
            <div>
              <Flashbar
                items={[
                  {
                    type: 'info',
                    content: `This is estimated time based on the current relocation speed and state of the cluster`,
                  },
                ]}
              />
              <Flashbar
                items={[
                  {
                    type: 'info',
                    content: `CLI Command: tumbler ${appContext.region.airportCode.toLowerCase()} aes domain bg-completion-time-estimate -D ${
                      props.domainIdentifier
                    }`,
                  },
                ]}
              />
            </div>
          }
        >
          <SpaceBetween direction="vertical" size="xxl">
            {loading ? (
              <Box textAlign="center">
                <Spinner size="large" />
              </Box>
            ) : (
              <SpaceBetween direction="vertical" size="l">
                <SpaceBetween direction="horizontal" size="l">
                  <Button variant="primary" onClick={refresh}>
                    Refresh
                  </Button>
                </SpaceBetween>
                <CodeViewer
                  codeData={JSON.stringify(output, null, 4)}
                  language={{ label: 'json', value: 'json' }}
                  theme={{ label: 'coy', value: 'coy' }}
                />
              </SpaceBetween>
            )}
          </SpaceBetween>
        </Container>
    );
  };
  
  export { BGEstimatedCompletion };
  