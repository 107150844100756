// @ts-ignore
import { Select } from '@amzn/awsui-components-react';
import React, { useContext } from 'react';
import { AppContext } from '../../../utils/appContext';
import './../../../Styles/navbar.css';

const tumblerBetaRegions = [
  { label: 'IAD (us-east-1)', value: 'iad-beta' },
  { label: 'DUB (eu-west-1)', value: 'dub-beta' },
  { label: 'PDX (us-west-2)', value: 'pdx-beta' },
  { label: 'MXP (eu-south-1)', value: 'mxp-beta' },
  // add new staging region here
].sort((a, b) => (a.value.toUpperCase() > b.value.toUpperCase() ? 1 : -1));

const tumblerGammaRegions = [
  { label: 'IAD (us-east-1)', value: 'iad-gamma' },
  { label: 'PDX (us-west-2)', value: 'pdx-gamma' },
].sort((a, b) => (a.value.toUpperCase() > b.value.toUpperCase() ? 1 : -1));

const tumblerProdRegions = [
  { label: 'IAD (us-east-1)', value: 'iad-prod' },
  { label: 'PDX (us-west-2)', value: 'pdx-prod' },
  { label: 'DUB (eu-west-1)', value: 'dub-prod' },
  { label: 'CDG (eu-west-3)', value: 'cdg-prod' },
  { label: 'CGK (ap-southeast-3)', value: 'cgk-prod' },
  { label: 'FRA (eu-central-1)', value: 'fra-prod' },
  { label: 'SFO (us-west-1)', value: 'sfo-prod' },
  { label: 'HKG (ap-east-1)', value: 'hkg-prod' },
  { label: 'KIX (ap-northeast-3)', value: 'kix-prod' },
  { label: 'ICN (ap-northeast-2)', value: 'icn-prod' },
  { label: 'SIN (ap-southeast-1)', value: 'sin-prod' },
  { label: 'GRU (sa-east-1)', value: 'gru-prod' },
  { label: 'BOM (ap-south-1)', value: 'bom-prod' },
  { label: 'SYD (ap-southeast-2)', value: 'syd-prod' },
  { label: 'YUL (ca-central-1)', value: 'yul-prod' },
  { label: 'ARN (eu-north-1)', value: 'arn-prod' },
  { label: 'LHR (eu-west-2)', value: 'lhr-prod' },
  { label: 'NRT (ap-northeast-1)', value: 'nrt-prod' },
  { label: 'CMH (us-east-2)', value: 'cmh-prod' },
  { label: 'BAH (me-south-1)', value: 'bah-prod' },
  { label: 'CPT (af-south-1)', value: 'cpt-prod' },
  { label: 'MXP (eu-south-1)', value: 'mxp-prod' },
  { label: 'DXB (me-central-1)', value: 'dxb-prod' },
  { label: 'ZAZ (eu-south-2)', value: 'zaz-prod' },
  { label: 'ZRH (eu-central-2)', value: 'zrh-prod' },
  { label: 'MEL (ap-southeast-4)', value: 'mel-prod' },
  { label: 'PDT (us-gov-west-1)', value: 'pdt-prod' },
  { label: 'OSU (us-gov-east-1)', value: 'osu-prod'},
  { label: 'HYD (ap-south-2)', value: 'hyd-prod'},
  { label: 'TLV (il-central-1)', value: 'tlv-prod'},
  { label: 'YYC (ca-west-1)', value: 'yyc-prod'},
  { label: 'KUL (ap-southeast-5)', value: 'kul-prod'},
  { label: 'BKK (ap-southeast-7)', value: 'bkk-prod'},
  // add new prod region here
].sort((a, b) => (a.value.toUpperCase() > b.value.toUpperCase() ? 1 : -1));

const tumblerStacks = [
  { label: 'Integ', value: 'gamma', options: tumblerGammaRegions },
  { label: 'Staging', value: 'beta', options: tumblerBetaRegions },
  { label: 'Prod', value: 'prod', options: tumblerProdRegions },
];

export const RegionSwitcher = () => {
  const appContext = useContext(AppContext);
  const host = window.location.host;
  let currentRegion = host.split('.')[0];
  let currentStage = host.split('.')[1];
  let defaultRegion;
  if (currentStage && currentRegion) {
    defaultRegion = tumblerStacks
      .find((stack) => stack.value === currentStage)
      .options.find(
        (region) => region.value === `${currentRegion}-${currentStage}`
      );
  }
  if (defaultRegion === undefined) {
    defaultRegion = {
      label: 'IAD (us-east-1)',
      value: `iad-${appContext.stage}`,
    };
  }

  const [selectedOption, setSelectedOption] = React.useState(defaultRegion);

  const handleChange = (event) => {
    const regionStage = event.detail.selectedOption.value.split('-');
    const region = regionStage[0];
    const stage = regionStage[1];
    setSelectedOption(event.detail.selectedOption);
    if (window.location.href.indexOf('lse-tool') > -1) {
      window.location.assign(
        `https://${region}.${stage}.tumbler.oss.aws.dev/lse-tool`
      );
    } else {
      window.location.assign(`https://${region}.${stage}.tumbler.oss.aws.dev`);
    }
  };
  return (
    <Select
      className="dropdown-nav"
      selectedOption={selectedOption}
      onChange={handleChange}
      options={tumblerStacks}
      ariaRequired
      selectedAriaLabel="Selected"
    />
  );
};
