import {
  Button,
  FormField,
  Grid,
  Multiselect,
  SelectProps,
} from '@amzn/awsui-components-react';
import React, { useCallback, useEffect, useState } from 'react';
import { CloudWatch, CloudwatchWidget } from '../../../Domain/Cloudwatch/CloudWatch';
import { Elb, ESEC2, InstanceVolumes } from '../../../models/types';
import './../../../Styles/cloudwatch-dasboard.css';
import { get } from 'lodash';
import { EC2_METRICS_OPTIONS } from './constants';
import {
  getEC2Metrics
} from './utils';

const statsOptions: SelectProps.Options = [
  { label: 'Maximum', value: 'Maximum' },
  { label: 'Minimum', value: 'Minimum' },
  { label: 'Average', value: 'Average' },
  { label: 'Sum', value: 'Sum' },
];

interface Ec2Form {
  selectedMetrics: SelectProps.Options;
  instanceIds: SelectProps.Options;
  stats: SelectProps.Options;
  isDirty: boolean;
}

interface Ec2Props {
  ec2Instances: ESEC2[];
  region: string;
  clientId: string;
  domainName: string;
  initialValues?: Ec2Form;
  rootAccountId: string;
  isOptInRegion: boolean;
}


const Ec2 = (props: Ec2Props) => {
  const instancesIds = props.ec2Instances.map(
    (instance: ESEC2) => instance.instanceId
  );
  const instanceIdOptions = instancesIds.map((instanceId) => ({ label: instanceId, value: instanceId }));
  const [metricOptions, setMetricOptions] = useState(EC2_METRICS_OPTIONS)
  const cloudWatchProps = {
    region: props.region,
    rootAccountId: props.rootAccountId,
    isOptInRegion: props.isOptInRegion || false,
  };
  const initialValues = props.initialValues
    ? props.initialValues
    : {
        selectedMetrics: [],
        instanceIds: [],
        stats: [{ label: 'Average', value: 'Average' }],
        isDirty: false,
      };
  const [formValues, setFormValues] = useState<Ec2Form>(initialValues);

  const [instanceIdSelected, setInstanceIdSelected] = useState([]);
  const [widget, setWidgets] = useState([]);
  const isFormInvalid = () => {
    return (
      formValues.stats.length === 0 ||
      formValues.selectedMetrics.length === 0
    );
  };

  const handleSubmit = useCallback(() => {
    setFormValues((prevFormValues: Ec2Form) => ({
      ...prevFormValues,
      isDirty: false,
    }));
    const stats = formValues.stats
      ? formValues.stats.map((stat: SelectProps.Option) =>
        stat ? stat.label : ''
      )
      : ['Average'];

    const selectedMetrics = formValues.selectedMetrics ? formValues.selectedMetrics.map(metric => metric.value) : undefined;
    const selectedInstanceIds = formValues.instanceIds && formValues.instanceIds.length > 0 ? formValues.instanceIds.map((instanceId: SelectProps.Option) => {
      return (instanceId ? instanceId.label : '')!
    }) : instancesIds;
    const ec2Widgets = getEC2Metrics(
      props.domainName,
      props.clientId,
      selectedInstanceIds,
      props.rootAccountId,
      props.region,
      stats,
      true,
      selectedMetrics
    ) as CloudwatchWidget[]
    setWidgets(ec2Widgets)
  }, [formValues]);

  // When it is initial autosubmit
  useEffect(() => {
    if (props.initialValues) {
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.initialValues]);

  return (
    <React.Fragment>
      <Grid
        className="dasboard-explorer"
        gridDefinition={[
          { colspan: 3 },
          { colspan: 3 },
          { colspan: 3 },
          { colspan: 1 },
        ]}
      >
        <FormField description="Choose Metric from the list." label="Metric">
          <Multiselect
            selectedOptions={formValues.selectedMetrics}
            deselectAriaLabel={(e) => 'Remove ' + e.label}
            options={metricOptions}
            placeholder="Choose options"
            selectedAriaLabel="Selected"
            filteringType="auto"
            onChange={({ detail }) =>
              setFormValues((prevFormValues: Ec2Form) => ({
                ...prevFormValues,
                selectedMetrics: detail.selectedOptions,
                isDirty: true,
              }))
            }
          />
        </FormField>
        <FormField description="Choose stats for the metric." label="Stats">
          <Multiselect
            selectedOptions={formValues.stats}
            deselectAriaLabel={(e) => 'Remove ' + e.label}
            options={statsOptions}
            placeholder="Choose options"
            selectedAriaLabel="Selected"
            onChange={({ detail }) =>
              setFormValues((prevFormValues: Ec2Form) => ({
                ...prevFormValues,
                stats: detail.selectedOptions,
                isDirty: true,
              }))
            }
          />
        </FormField>
        <FormField
            description="Choose Instance IDs to look for metrics."
            label="Instance ID"
        >
            <Multiselect
                disabled={instanceIdOptions.length === 0}
                invalid={formValues.stats.length === 0}
                selectedOptions={formValues.instanceIds}
                deselectAriaLabel={(e) => 'Remove ' + e.label}
                options={instanceIdOptions}
                placeholder="Choose options"
                selectedAriaLabel="Selected"
                filteringType="auto"
                onChange={({detail}) =>
                    setFormValues((prevFormValues: Ec2Form) => ({
                        ...prevFormValues,
                        instanceIds: detail.selectedOptions,
                        isDirty: true,
                    }))
                }
            />
        </FormField>
        <Button
          className="explorer-button-checkbox"
          variant="primary"
          disabled={isFormInvalid()}
          onClick={handleSubmit}
        >
          Fetch
        </Button>
      </Grid>
        {widget && widget.length > 0 ? (
        <CloudWatch
          region={props.region}
          rootAccountId={props.rootAccountId}
          isOptInRegion={props.isOptInRegion}
          widgets={widget}
        />
      ) : null}
    </React.Fragment>
  );
};

export { Ec2 };