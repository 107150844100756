import {
  Box,
  BreadcrumbGroup,
  Grid,
  Icon,
  Tabs,
  Alert,
} from '@amzn/awsui-components-react';
import { EuiCallOut, EuiSpacer } from '@elastic/eui';
import { get } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
// @ts-ignore
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { ApiExplorer } from '../../Domain/ApiExplorer/ApiExplorer';
import { DomainConfig } from '../../Domain/DomainConfig/DomainConfig';
import { UpgradeDetails } from '../../Domain/UpgradeDetails/UpgradeDetails';
import { Elb, ESEC2 } from '../../models/types';
import { AppContext } from '../../utils/appContext';
import { BREADCRUMBS } from '../../utils/constants';
import { getHealthyInstance } from '../../utils/helpers';
import { HawkEye } from './../../Domain/HawkEye/HawkEye';
import { DomainActions } from './DomainActions';
import { PerformanceAnalyser} from "../../Domain/PerformanceAnalyser/PerformanceAnalyser";
import {QueryInsights} from "../../Domain/QueryInsights/QueryInsights"
import { DomainIdentifier } from './DomainIdentifier';
import { useResourceInfoQuery } from './hooks/useResourceInfoQuery';
import { Overview } from './Overview/Overview';
// @ts-ignore
import { DomainEvents } from '../../Domain/DomainEvents/DomainEvents';
import { KeyEvents } from '../../Domain/KeyEvents/KeyEvents';
import { Logs } from '../../Domain/Logs/Logs';
import '../../Styles/domain-details.css';
import { SdpdsClusterDetails } from './SDPDS';
import { SkewAnalysis } from './StorageConsole/SkewAnalysis/SkewAnalysis';
import { SnapshotsInfo } from './StorageConsole/SnapshotsInfo/SnapshotsInfo';

import { ErrorBoundary } from 'react-error-boundary';
import { Fallback } from "../../Components/Fallback";
import {ErrorCodes, isError} from "../../ErrorHandler/apiErrorHandler";
import { refreshData } from "./utils/refreshData";
import { useDomainListQuery } from "../AccountOverview/hooks/useDomainListsQuery";

interface DetailsProps {
  domainIdentifier: string;
  authUser: boolean;
}

interface DomainDetailsParams {
  clientId: string;
  domainName: string;
}

const DomainDetails = (props: DetailsProps) => {
  const params = useParams();
  const tab = params['tab'];

  const appContext = useContext(AppContext);
  const { domainName, clientId } = useParams<DomainDetailsParams>();
  const { pathname, hash } = useLocation();
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState(null)
  const [isFetching, setIsFetching] = useState(false);

  const { loading:domainListLoading, data:domainListData, error: domainListError } = useDomainListQuery({ clientId });

  const {
    loading: resourceLoading,
    data,
    error,
    refetch,
  } = useResourceInfoQuery({
    domainIdentifier: props.domainIdentifier,
  });

  const isErrorPresent = isError(error)
  const isDomainListErrorPresent = isError(domainListError)
   useEffect(() => {
   if ( isErrorPresent) {
       const errorMessage = error.message
       if (errorMessage.includes('unexpected character') || errorMessage.includes('Unexpected token')) {
         setErrorMessage("Unable to get domain details. Failed with " + ErrorCodes.BAD_GATEWAY + " Timeout. Please reload the page or try again later");  //add logging later
       } else {
         setErrorMessage("Unable to get domain details. Failed with " + error.message);
       }
    }
  }, [isErrorPresent, error]);
  const handleRefreshData = async () => {
    await refreshData(refetch, setIsFetching, setErrorMessage, isFetching, isErrorPresent, error, 'domainDetails');
  };

  const ec2Instances: ESEC2[] = get(
    data,
    'domain.resources.ALL.ec2Instances',
    []
  );
  const esVersion: string = get(data, 'domain.esVersion', undefined);
  const cellId: string = get(data, 'domain.cellId', undefined);
  const HAProxyVersion: string = get(data, 'domain.HAProxyVersion', undefined);
  const HAProxyType: string = get(data, 'domain.HAProxyType', undefined);
  const pinnedVFIDomain: string = get(
    data,
    'domain.pinnedVFIDomain',
    undefined
  );
  const pinnedVFIClient: string = get(
    data,
    'domain.pinnedVFIClient',
    undefined
  );
  const IsSensitiveDomain: string = get(
    data,
    'domain.IsSensitiveDomain',
    undefined
  );
  const IsSensitiveClient: string = get(
    data,
    'domain.IsSensitiveClient',
    undefined
  );
  const IsDomainIsolated: string = get(
    data,
    'domain.IsDomainIsolated',
    undefined
  );

  const PackageTypeAssociatedOnDomain: string = get(
    data,
    'domain.PackageTypeAssociatedOnDomain',
    undefined
  );

  const carbonDomain: string = get(data, 'domain.carbonDomain', undefined);
  const elb: Elb = get(data, 'domain.resources.ALL.elb', {
    targetGroupName: 'string',
    loadBalancerName: '',
  });
  const DomainCreateDateTime: string = get(
      data,
      'domain.DomainCreateDateTime',
      undefined
  );

  let isDomainUnknownOrDeleted = false
  //TODO need to fix Network error on firefox
  if (!(domainListError && domainListError.message.includes("NetworkError when attempting to fetch resource"))) {
    const domainList = get(domainListData, 'account.domains', []);
    const domainExists = domainList.some(domain => (domain.domainIdentifier === props.domainIdentifier));
    isDomainUnknownOrDeleted = !domainListLoading && !domainExists;
  }

  const isShareMetricRoute = hash ? hash.indexOf('shared-metric') > -1 : false;

  const healthyInstance = getHealthyInstance(ec2Instances);

  const dragonstoneDomain = get(data, 'domain.dragonstoneDomain', undefined);
  
  const IsRemotePublicationEnabled = get(data, 'domain.IsRemotePublicationEnabled', undefined)

  console.log("is remote publication", IsRemotePublicationEnabled)

  // const awsAccountId = "915766840868"
  // const awsAccountId = props.domainIdentifier.split(":")[0]
  // console.log(awsAccountId)
  // const { loading, data: OtsDomainData, error: OtsDomainError } = useOtsItemsQuery({ awsAccountId });
  // const { loading, data: OtsDomainData, error: OtsDomainError } = useOtsAllQuery();
  // const otsAll = get(OtsDomainData, 'otsAll.otsItems', []);

  // let isOtsDomain = otsAll.find(record => record["account_id"] === awsAccountId)
  // console.log("domainFound")
  // console.log(isOtsDomain)

  // if (isOtsDomain) {
  //   console.log("It is an OTS Domain")
  // }

  useEffect(() => {
    appContext.setBreadcrumbs([
      {
        ...BREADCRUMBS.TUMBLER,
        href: '/',
        onClick: () => {
          history.push('/');
        },
      },
      {
        text: clientId,
        href: `/${clientId}`,
        onClick: () => {
          history.push(`/${clientId}`);
        },
      },
      { text: domainName, href: `/${clientId}/${domainName}` },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //

  var currentTab;

  if (tab) {
    currentTab = tab;
  } else {
    currentTab = 'overview';
    var newPath = pathname;
    if (pathname.slice(-1) === '/') {
      newPath = pathname.slice(0, -1);
    }
    history.replace({ pathname: newPath + `/overview` });
  }

  const [activeTab, setActiveTab] = useState(currentTab);

  const handleTabChange = (selectedTab) => {
    var refresh =
      pathname.split('/').slice(0, -1).join('/') + `/${selectedTab}`;
    // console.log("refresh",refresh)
    history.push({ pathname: `${refresh}` });
    setActiveTab(selectedTab);
  };

  const renderTabs = () => (
    <Tabs
      tabs={tabs}
      activeTabId={activeTab}
      onChange={({ detail }) => handleTabChange(detail.activeTabId)}
    />
  );

  const tabs = [
    {
      id: 'overview',
      label: 'Overview',
      route: 'overview',
      content: (
        <ErrorBoundary fallbackRender={props => <Fallback error={props.error} failedComponent={"OverviewTab"}/>}>
        <Overview
          domainIdentifier={props.domainIdentifier}
          resourceLoading={resourceLoading}
          ec2Instances={ec2Instances}
          elb={elb}
          esVersion={esVersion || 'N/A'}
          cellId={cellId || (appContext.rootAccountId || 'N/A')}
          HAProxyVersion={HAProxyVersion || 'N/A'}
          HAProxyType={HAProxyType || 'N/A'}
          pinnedVFIDomain={pinnedVFIDomain || 'N/A'}
          pinnedVFIClient={pinnedVFIClient || 'N/A'}
          IsSensitiveDomain={IsSensitiveDomain || 'N/A'}
          IsSensitiveClient={IsSensitiveClient || 'N/A'}
          IsDomainIsolated={IsDomainIsolated || 'N/A'}
          carbonDomain={carbonDomain || 'N/A'}
          domainData={data}
          refreshResources={refetch}
          errorMessage={errorMessage}
          handleRefreshData={handleRefreshData}
          isFetching={isFetching}
          dragonstoneDomain={dragonstoneDomain}
          IsRemotePublicationEnabled={IsRemotePublicationEnabled}
          DomainCreateDateTime={DomainCreateDateTime || 'N/A'}
          PackageTypeAssociatedOnDomain={PackageTypeAssociatedOnDomain || 'N/A'}
        />
        </ErrorBoundary>
      ),
    },
    {
      id: 'logs',
      label: 'Logs',
      route: 'logs',
      content:
        <ErrorBoundary fallbackRender={props => <Fallback error={props.error} failedComponent={"LogsTab"}/>}>
          <Logs 
              authUser = {props.authUser}
          />
        </ErrorBoundary>,
    },
    {
      id: 'apiExplored',
      label: 'API explorer',
      route: 'explorer',
      content: (
        <ErrorBoundary fallbackRender={props => <Fallback error={props.error} failedComponent={"APIExplorerTab"}/>}>
        <ApiExplorer
          domainIdentifier={props.domainIdentifier}
          instanceId={healthyInstance ? healthyInstance.instanceId : ''}
          esVersion={esVersion || 'N/A'}
          nodeCount={ec2Instances.length}
        />
        </ErrorBoundary>
      ),
    },
    {
      id: 'configurations',
      label: 'Configurations',
      route: 'configurations',
      content:
        <ErrorBoundary fallbackRender={props => <Fallback error={props.error} failedComponent={"ConfigurationsTab"}/>}>
          <DomainConfig domainIdentifier={props.domainIdentifier} />
        </ErrorBoundary>,
    },
    {
      id: 'snapshots',
      label: 'Snapshots',
      route: 'snapshots',
      content: (
        // <ContentPanel title={'Snapshots Info'}>
        <ErrorBoundary fallbackRender={props => <Fallback error={props.error} failedComponent={"SnapshotsTab"}/>}>
        <SnapshotsInfo
          domainIdentifier={props.domainIdentifier}
          resourceLoading={resourceLoading}
          domainData={data}
        />
        </ErrorBoundary>
        // </ContentPanel>
      ),
    },
    {
      id: 'skewAnalysis',
      label: 'Skew analysis',
      route: 'skew-analysis',
      content: (
        <ErrorBoundary fallbackRender={props => <Fallback error={props.error} failedComponent={"SkewAnalysisTab"}/>}>
        <SkewAnalysis
          domainIdentifier={props.domainIdentifier}
          resourceLoading={resourceLoading}
          domainData={data}
        />
        </ErrorBoundary>
      ),
    },
    {
      id: 'upgradeHistory',
      label: 'Upgrade history',
      route: 'upgrade-history',
      content: (
        // <ContentPanel title={'Upgrade details'}>
        <ErrorBoundary fallbackRender={props => <Fallback error={props.error} failedComponent={"UpgradeHistoryTab"}/>}>
        <UpgradeDetails domainIdentifier={props.domainIdentifier} />
        </ErrorBoundary>
        // </ContentPanel>
      ),
    },
    {
      id: 'keyEvents',
      label: 'Key Events',
      route: 'key-events',
      content:
        <ErrorBoundary fallbackRender={props => <Fallback error={props.error} failedComponent={"KeyEventTab"}/>}>
          <KeyEvents domainIdentifier={props.domainIdentifier} />
        </ErrorBoundary>,
    },
    {
      id: 'domainEvents',
      label: 'Domain Events',
      route: 'domain-events',
      content:
        <ErrorBoundary fallbackRender={props => <Fallback error={props.error} failedComponent={"DomainEventsTab"}/>}>
          <DomainEvents domainIdentifier={props.domainIdentifier} />
        </ErrorBoundary>,
    },
    {
      id: 'sdpds',
      label: 'SDPDS',
      route: 'sdpds',
      content: (
        <ErrorBoundary fallbackRender={props => <Fallback error={props.error} failedComponent={"SDPDSTab"}/>}>
        <SdpdsClusterDetails domainIdentifier={props.domainIdentifier} />
        </ErrorBoundary>
      ),
    },
    {
      id: 'queryInsights',
      label: 'Query Insights',
      route: 'query-insights',
      content: (
        <ErrorBoundary fallbackRender={props => <Fallback error={props.error} failedComponent={"QueryInsightsTab"}/>}>
          <QueryInsights
            domainIdentifier={props.domainIdentifier}
            authUser={props.authUser}
            instanceId={healthyInstance ? healthyInstance.instanceId : ''}
          />
        </ErrorBoundary>
      ),
    },
    {
      id: 'performanceAnalyser',
      label: 'Performance Analyser',
      route: 'performance-analyser',
      content: (
        <ErrorBoundary fallbackRender={props => <Fallback error={props.error} failedComponent={"PerformanceAnalyserTab"}/>}>
          <PerformanceAnalyser
              domainIdentifier={props.domainIdentifier}
              authUser = {props.authUser}
              instanceId={healthyInstance ? healthyInstance.instanceId : ''}
          />
        </ErrorBoundary>
      ),
    },
    {
      id: 'hawkeye',
      label: 'Hawk Eye (Preview)',
      route: 'hawkeye',
      content:
        <ErrorBoundary fallbackRender={props => <Fallback error={props.error} failedComponent={"HawkEyeTab"}/>}>
          <HawkEye domainIdentifier={props.domainIdentifier} />
        </ErrorBoundary>,
    },
  ];

  return (
    <React.Fragment>
      {/* {isOtsDomain && <Flashbar className="otsBanner" items={[
        {
          type: "warning",
          content: "This is OTS domain",
        }
      ]} />} */}
      <Box className="topMessage" variant="div">
        <Icon name="status-info" size="normal" variant="normal" /> &nbsp; We are
        aware of{' '}
        <a
          href="https://w.amazon.com/bin/view/AWS/SearchServices/OpsTumbler/Release#HActivelyWorkingOn"
          target="_blank"
          rel="noopener noreferrer"
        >
          these
        </a>{' '}
        bugs/issues on which team is currently working on!
      </Box>
      <Alert header="Note" statusIconAriaLabel="Warning" type="warning">
        Few OpsTumbler commands can have access to customer data, please exercise caution while using commands that has access to customer data.
      </Alert>
      <BreadcrumbGroup items={appContext.breadcrumbs} />
      {isDomainListErrorPresent ? (
        <div>
          <EuiCallOut
            title={
              <span>
                <b>Something went wrong</b>
              </span>
            }
            color="danger"
            iconType="alert"
            size="m"
          >
            <p>
              Please refesh the page or try again later
            </p>
          </EuiCallOut>
          <EuiSpacer />
        </div>
      ) : (isDomainUnknownOrDeleted ? (
        <div>
          <EuiCallOut
            title={
              <span>
                Sorry, domain identifier <b>{props.domainIdentifier}</b> is
                either <b>deleted</b> or <b>doesn't exist</b>
              </span>
            }
            color="danger"
            iconType="alert"
            size="m"
          >
            <p>
              You may navigate to domain list for AccountId{' '}
              <Link to={`/${clientId}`}>{clientId}</Link> or switch to correct
              region from region switcher.
            </p>
          </EuiCallOut>
          <EuiSpacer />
        </div>
      ): null)}

      <Grid
        gridDefinition={[
          { colspan: { default: 1, xxs: 11 } },
          { colspan: { default: 11, xxs: 1 } },
        ]}
      >
        <DomainIdentifier domainIdentifier={props.domainIdentifier} />
        <DomainActions
          domainIdentifier={props.domainIdentifier}
          ec2Instances={[]}
        />
      </Grid>

      {isShareMetricRoute ? null : renderTabs()}
    </React.Fragment>
  );
};
export { DomainDetails };
